import Vue from 'vue'
import VueRouter from 'vue-router'
import routesPpob from './router_ppob'
import routesExt from './router_ext'
import routesExamples from './router_examples'

import { auth, authFetch } from '@/libs/hxcore';
// require('@/libs/adminlte');

Vue.use(VueRouter)

const Login = {
    template: '<div>Login</div>'
}

let dashboardSetting = '';
let routes = [{
        path: '/',
        name: 'Dashboard',
        component: (e) => {
            let info = auth.user();
            if (info.role_id > 10) {
                if (info.role_id == 12) {
                    return import ('@/views/DashboardShipperCs.vue');
                }

                if (dashboardSetting == 'reguler') {
                    return import ('@/views/DashboardShipperNew.vue');
                } else {
                    return import ('@/views/DashboardShipperLite.vue');
                }
            } else if (info.role_id == 9) {
                return import ('@/views/DashboardKurir.vue');
            } else if (info.role_id < 4) {
                return import ('@/views/DashboardAdmin.vue');
            } else {
                return import ('@/views/DashboardAdmin.vue');
            }
        },
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            // code: info.role_id > 10 ? 'cod.dashboard.shipper'
            //     : info.role_id == 9 ? 'cod.dashboard.kurir'
            //         : info.role_id < 4 ? 'cod.dashboard.admin'
            //             : ''
        }
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () =>{
            if (dashboardSetting == 'reguler') {
                return import ('@/views/DashboardShipperNew.vue');
                // console.log('ETSTES')
            } else {
                return import ('@/views/DashboardShipperLite.vue');
            }
        },
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.dashboard.shipper'
        }
    },
    {
        path: '/user',
        name: 'Pengguna',
        component: () =>
            import ('@/views/User.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'core.admin.user'
        }
    },
    {
        path: '/table',
        name: 'Table',
        component: () =>
            import ('@/views/Table.vue'),
        meta: {
            layout: 'AppLayoutAdmin'
        }
    },
    {
        path: '/tabletrans',
        name: 'Table Transient',
        component: () =>
            import ('@/views/Tabletransient.vue'),
        meta: {
            layout: 'AppLayoutAdmin'
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () =>
            import ('@/views/Login.vue')
    },
    {
        path: '/resetpass/:id',
        name: 'Reset Password',
        component: () =>
            import ('@/views/ResetPassword.vue')
    },
    {
        path: '/activate/:id',
        name: 'Aktivasi User',
        component: () =>
            import ('@/views/UserActivate.vue')
    },
    {
        path: '/user/group',
        name: 'Grup Pengguna',
        component: () =>
            import ('@/views/UserGroup.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'core.admin.usergroup'
        }
    },

    {
        path: '/permit/:id',
        name: 'Permission',
        component: () =>
            import ('@/views/Permit.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'core.roles'
        }
    },
    {
        path: '/logwatch',
        name: 'Log Activity',
        component: () =>
            import ('@/views/LogActivity.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'adm.logwatch'
        }
    },
    {
        path: '/logfile',
        name: 'Log History',
        component: () =>
            import ('@/views/LogFile.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'adm.logwatch'
        }
    },
    {
        path: '/user/menu',
        name: 'Menu',
        component: () =>
            import ('@/views/Menu.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'core.admin.menu'
        }
    },
    {
        path: '/user/module',
        name: 'Module',
        component: () =>
            import ('@/views/Module.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/tracking',
        name: 'Cek Tracking',
        component: () =>
            import ('@/views/CekTracking.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'core.admin.tracking'
        }
    },
    {
        path: '/user/setting',
        name: 'Pengaturan Aplikasi',
        component: () =>
            import ('@/views/Setting.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'core.admin.setting'
        }
    },
    {
        path: '/maintenance',
        name: 'Maintenance',
        component: () =>
            import ('@/views/Maintenance.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
        }
    },
    {
        path: '/ongkir',
        name: 'Cek Ongkir',
        component: () =>
            import ('@/views/Ongkir.vue')
    },
    {
        path: '/ongkir/embed',
        name: 'Cek Ongkir',
        component: () =>
            import ('@/views/OngkirEmbed.vue')
    },
    {
        path: '/lacak',
        name: 'Lacak Pesanan',
        component: () =>
            import ('@/views/Lacak.vue')
    },
    {
        path: '/lacak/:id',
        name: 'Lacak Pesanan',
        component: () =>
            import ('@/views/Lacak.vue')
    },
    {
        path: '*',
        name: '404 Not Found',
        component: () =>
            import ('@/views/NotFound.vue')
    },
]

routes = routes.concat(routesExt)
routes = routes.concat(routesExamples)
routes = routes.concat(routesPpob)
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    authFetch("/publik/setting")
        .then((res) => {
            if (res.status === 201) {} else if (res.status === 400) {}
            return res.json();
        })
        .then((js) => {
            dashboardSetting = js.settings;
            // alert(settings);
        });

    if (to.matched.some(record => record.meta.requiresAuth)) {
        //Vue.$root.$emit('onRouteChanged', 'new message!');
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!localStorage.APP_TOKEN) {
            next({ name: 'Login' })
        } else {
            // get permission info from server
            to.params.roles = [];
            var moduleCode = to.meta.code ? to.meta.code : '';
            authFetch('/users/profile/module', {
                    method: "POST",
                    body: 'code=' + moduleCode,
                })
                .then((res) => {
                    if (res.status === 201) {} else if (res.status === 400) {} else if (res.status === 403) {
                        localStorage.APP_TOKEN = '';
                        next({ path: '/login' })
                        return null;
                    }
                    return res.json();
                })
                .then((js) => {
                    if (js.success) {
                        to.params.roles = js.roles;
                        //record.meta.roles = record.name+Date.now();{query:{roles:record.name+Date.now()}}
                        next({ params: to.params })
                    } else if (js.status === false) {
                        localStorage.APP_TOKEN = '';
                        next({ path: '/login' })
                    }

                });

        }
    } else {
        if (localStorage.APP_TOKEN && to.path === '/login') {
            next({ path: '/' })
        } else next() // does not require auth, make sure to always call next()!
    }
})
export default router