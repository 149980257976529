const sideMenu = [
    { label: 'Dashboard', url: '/dashboard-admin', icon: 'fi fi-rr-dashboard' },
    { label: 'Cek Status', url: '/cek', icon: 'fi fi-rr-search-location' },
    { label: 'Riwayat Kiriman', url: '/riwayat/admin/all', icon: 'fi fi-rr-time-past' },
    { label: 'Payment', url: '/payment-admin/all', icon: 'fi fi-rr-wallet' },
    //   { label: 'Payment', url: '/payment', icon: 'fi fi-rr-receipt' },
    { label: 'Profil', url: '/profile', icon: 'fi fi-rr-user' },
    {
        label: 'Pengumuman',
        url: '#',
        icon: 'fi fi-rr-megaphone',
        menus: [
            { label: 'Blast', url: '/blast-notif', icon: 'fi fi-rr-circle' },
            { label: 'List Pengumuman', url: '/pengumuman', icon: 'fi fi-rr-circle' },
        ]
    },
    {
        label: 'Data Rekonsiliasi',
        url: '#',
        icon: 'fi fi-rr-money-bill-wave',
        menus: [
            { label: 'Rekonsiliasi', url: '/rekonsiliasi', icon: 'fi fi-rr-circle' },
            // { label: 'Upload <br> Rekonsiliasi Shipper', url: '/rekonsiliasi/shipper/upload', icon: 'fi fi-rr-circle' },
            { label: 'Upload <br> Rekonsiliasi Kurir', url: '/rekonsiliasi/kurir/upload', icon: 'fi fi-rr-circle' },
            { label: 'Riwayat Rekonsiliasi', url: '/rekonsiliasi/riwayat', icon: 'fi fi-rr-circle' },
        ]
    },
    {
        label: 'Data Shipper',
        url: '#',
        icon: 'fi fi-rr-folder',
        menus: [
            { label: 'Shipper', url: '/shipper', icon: 'fi fi-rr-circle' },
            { label: 'Rekening Shipper', url: '/shipper/rekening', icon: 'fi fi-rr-credit-card' },
            { label: 'Shipper CS', url: '/settings/user', icon: 'fa fa-users' },
            { label: 'Gudang Shipper', url: '/settings/shipper/address', icon: 'fi fi-rr-circle' },
            { label: 'Kiriman', url: '/shipper/recap', icon: 'fi fi-rr-circle' },
            { label: 'Top-Up', url: '/saldo/topup/approval', icon: 'fi fi-rr-circle' },
            { label: 'Withdraw', url: '/saldo/withdraw/approval', icon: 'fi fi-rr-circle' },
            { label: 'Rekap Saldo', url: '/saldo/shipper', icon: 'fi fi-rr-circle' },
            { label: 'Financial Report', url: '/finance/shipper', icon: 'fi fi-rr-circle' },
            { label: 'Afiliator', url: '/afiliator', icon: 'fi fi-rr-circle' },
            { label: 'Reward Afiliasi', url: '/afiliasi-shipper', icon: 'fi fi-rr-circle' },
            { label: 'Verifikasi KTP', url: '/shipper/verification', icon: 'fi fi-rr-circle' },
            { label: 'Generate Kurir Shipper', url: '/shipper/courier/generate', icon: 'fi fi-rr-circle' },
            // { label: 'Pergerakan<br>Saldo Shipper', url: '/saldo/approval', icon: 'fi fi-rr-circle' },
        ]
    },
    //{ label: 'Buyer', url: '/buyer', icon: 'fa fa-users' },

    {
        label: 'Tiket & Klaim',
        url: '#',
        icon: 'fi fi-rr-ticket',
        menus: [{
                label: 'Buat Tiket Baru',
                url: () => {
                    //this.$root.$emit('createTicket', 3);
                },
                icon: 'fi fi-rr-circle'
            },
            { label: 'Daftar Tiket', url: '/settings/tickets/all', icon: 'fi fi-rr-circle' },
            { label: 'Daftar Klaim', url: '/settings/tickets/claim', icon: 'fi fi-rr-circle' },
        ]
    },

    {
        label: 'Arsip',
        url: '#',
        icon: 'fi fi-rr-box',
        menus: [
            { label: 'Arsip Riwayat Kiriman', url: '/arsip/riwayat', icon: 'fi fi-rr-circle' },
            { label: 'Arsip Daftar Ticket', url: '/arsip/ticket', icon: 'fi fi-rr-circle' },
            { label: 'Arsip Daftar Klaim', url: '/arsip/claim', icon: 'fi fi-rr-circle' },
        ]
    },

    {
        label: 'Master',
        url: '#',
        icon: 'fi fi-rr-ballot',
        menus: [
            { label: 'Kurir', url: '/settings/courier', icon: 'fi fi-rr-shipping-fast' },
            { label: 'User Kurir', url: '/user/kurir', icon: 'fi fi-rr-package' },
            { label: 'Service Kurir', url: '/settings/courier_service', icon: 'fi fi-rr-truck-box' },
            { label: 'Jenis Member', url: '/settings/membertype', icon: 'fi fi-rr-membership-vip' },
            { label: 'Kode Tujuan', url: '/settings/destination', icon: 'fi fi-rr-circle' },
            { label: 'Pages', url: '/info', icon: 'fi fi-rr-circle' },
            { label: 'Rekening BOSCOD', url: '/settings/banklist', icon: 'fi fi-rr-circle' },
            { label: 'Afiliasi', url: '/afiliasi', icon: 'fi fi-rr-circle' },
        ]
    },
    {
        label: 'Laporan',
        url: '#',
        icon: 'fi fi-rr-document-signed',
        menus: [
            { label: 'Generate Jurnal Manual', url: '/jurnal/generate', icon: 'fi fi-rr-circle' },
            { label: 'Generate Jurnal Manual Batch', url: '/jurnal/generate-batch', icon: 'fi fi-rr-circle' },
            { label: 'Buku Besar', url: '/report/bukubesar', icon: 'fi fi-rr-circle' },
            { label: 'Trial Balance', url: '/report/trialbalance', icon: 'fi fi-rr-circle' },
            { label: 'Bospay', url: '/report/bospay', icon: 'fi fi-rr-circle' },
            { label: 'Anomali Bospay', url: '/report/anomali-bospay', icon: 'fi fi-rr-circle' },
            { label: 'Hutang Usaha (Kurir)', url: '/report/hutang/kurir', icon: 'fi fi-rr-circle' },
            { label: 'Hutang COD (Shipper)', url: '/report/hutang/shipper', icon: 'fi fi-rr-circle' },
            { label: 'Piutang', url: '/report/piutang-new', icon: 'fi fi-rr-circle' },
            { label: 'PPN Masukan', url: '/report/ppn-masukan', icon: 'fi fi-rr-circle' },
            { label: 'PPN Keluaran', url: '/report/ppn-keluaran', icon: 'fi fi-rr-circle' },
            { label: 'Laba Rugi', url: '/report/labarugi', icon: 'fi fi-rr-circle' },
            { label: 'Neraca', url: '/report/neraca', icon: 'fi fi-rr-circle' },
            { label: 'Jurnal Entri', url: '/report/jurnal-entri', icon: 'fi fi-rr-circle' },
            { label: 'Jurnal Group', url: '/report/jurnal-group', icon: 'fi fi-rr-circle' },
            { label: 'Request Approval', url: '/report/jurnal-request', icon: 'fi fi-rr-circle' },
            { label: 'Kewajaran', url: '/report/kewajaran', icon: 'fi fi-rr-circle' },
            { label: 'Tutup Buku', url: '/report/close-jurnal', icon: 'fi fi-rr-circle' },
        ]
    },
    {
        label: 'Winpay',
        url: '#',
        icon: 'fi fi-rr-money-wings',
        menus: [
            { label: 'Laporan Payment Gateway', url: '/report/winpay', icon: 'fi fi-rr-circle' },
            { label: 'Upload Rekonsiliasi Winpay', url: '/rekonsiliasi/winpay', icon: 'fi fi-rr-circle' }
        ]
    },
    {
        label: 'PPOB',
        url: '#',
        icon: 'fi fi-rr-money-transfer-smartphone',
        menus: [
            { label: 'Transaksi PPOB', url: '/ppob/history', icon: 'fi fi-rr-circle' },
            { label: 'Produk PPOB', url: '/ppob/products', icon: 'fi fi-rr-circle' },
            { label: 'Upload Rekon PPOB Winpay', url: '/ppob/rekonsiliasi/winpay', icon: 'fi fi-rr-circle' },
            { label: 'Laporan Subsidiary Ledger akun Uang Muka', url: '/ppob/subsidiary-ledger', icon: 'fi fi-rr-circle' },
        ]
    },
    {
        label: 'Administration',
        url: '#',
        icon: 'fi fi-rr-admin-alt',
        menus: [
            { label: 'User', url: '/user', icon: 'fi fi-rr-circle' },
            { label: 'Group', url: '/user/group', icon: 'fi fi-rr-circle' },
            { label: 'Activity', url: '/logwatch', icon: 'fi fi-rr-time-watch-calendar' },
            { label: 'Menu', url: '/user/menu', icon: 'fi fi-rr-circle' },
            { label: 'Log History', url: '/logfile', icon: 'fi fi-rr-circle' },
            { label: 'Cek Tracking <br> Kurir', url: '/tracking', icon: 'fi fi-rr-circle' },
            { label: 'Pengaturan Aplikasi', url: '/user/setting', icon: 'fi fi-rr-circle' },
        ]
    }

];
export default sideMenu;
